<template>
  <div>
    <h2>About</h2>
    <p>This is the about page content.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>
