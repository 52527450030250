<template>
  <div id="app">
    <AppHeader />
    <main>
      <div class="page-container">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#app {
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
}

main {
  margin-top: 120px;
  padding: 20px;
}

.page-container {
  max-width: 1000px;
  margin: 0 auto;
}
</style>

