<template>
  <div>
    <h2>Legal</h2>
    <p>This is the legal page content.</p>
  </div>
</template>

<script>
export default {
  name: 'LegalPage',
};
</script>
