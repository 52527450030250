<template>
  <div class="projects-list">
    <div v-for="project in projects" :key="project.id" class="project">
      <img :src="project.image" :alt="project.title" class="project-image" />
      <div class="project-description">
        <h3>{{ project.title }}</h3>
        <p>{{ project.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsPage',
  data() {
    return {
      projects: [
        {
          id: 1,
          title: 'Project 1',
          description: 'Description of Project 1. This is a longer description that explains what the project is about and what technologies were used.',
          image: 'https://via.placeholder.com/400x300'
        },
        {
          id: 2,
          title: 'Project 2',
          description: 'Description of Project 2. This is a longer description that explains what the project is about and what technologies were used.',
          image: 'https://via.placeholder.com/400x300'
        },
        {
          id: 3,
          title: 'Project 3',
          description: 'Description of Project 3. This is a longer description that explains what the project is about and what technologies were used.',
          image: 'https://via.placeholder.com/400x300'
        }
      ],
    };
  },
};
</script>

<style scoped>
.projects-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.project {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: transform 0.2s;
}

.project:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.project-image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.project-description {
  flex: 1;
  text-align: left;
}

h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
}

p {
  margin: 0;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .project {
    flex-direction: column;
  }

  .project-description {
    text-align: left;
  }

  .project-image {
    width: 100%;
    max-width: 400px;
  }
}
</style>
