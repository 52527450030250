<template>
  <header>
    <h1 class="site-title">PASMONICK</h1>
    <!-- Desktop Navigation -->
    <nav class="desktop-nav">
      <router-link to="/">Projects</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/legal">Legal</router-link>
    </nav>
    
    <!-- Mobile Navigation -->
    <div class="mobile-nav">
      <button @click="toggleMenu" class="menu-button">
        <span class="material-icons">{{ isMenuOpen ? 'close' : 'menu' }}</span>
      </button>
      <div class="mobile-menu" :class="{ 'is-open': isMenuOpen }">
        <router-link to="/" @click="closeMenu">Projects</router-link>
        <router-link to="/about" @click="closeMenu">About</router-link>
        <router-link to="/legal" @click="closeMenu">Legal</router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 2rem;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.site-title {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 500;
  line-height: 60px;
}

.desktop-nav {
  display: flex;
  gap: 2rem;
  height: 100%;
  align-items: center;
}

.mobile-nav {
  display: none;
}

nav a, .mobile-menu a {
  text-decoration: none;
  color: #2c3e50;
  font-weight: 500;
}

nav a:hover, .mobile-menu a:hover {
  color: #42b983;
}

nav a.router-link-active, .mobile-menu a.router-link-active {
  color: #42b983;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  height: 100%;
}

.mobile-menu {
  position: fixed;
  top: 60px;
  right: -100%;
  background-color: #f8f9fa;
  width: 200px;
  padding: 1rem;
  box-shadow: -2px 2px 4px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobile-menu.is-open {
  right: 0;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  
  .mobile-nav {
    display: block;
    height: 100%;
  }
}
</style>
